import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import TextField from '@material-ui/core/TextField'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { pdf } from '@react-pdf/renderer'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SignatureCanvas from 'react-signature-canvas'
import Footer from '../components/Footer'
import CreditApplicationPdf from '../components/Forms/CreditApplication/CreditApplicationPdf'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { encodeToFormData, getFormattedTime } from '../utils'



class CreditAppForm extends Component {
  constructor(props) {
    super(props)
    this.steps = [
      'Parcel Information',
      'Bank Transfer',
      'Bank References',
      'Success',
    ]
    this.state = this.getInitState()
    this.sigPad = null
  }

  getInitState = () => ({
    activeStep: 0,
    status: 'init',
    formData: {
      legalName: '',
      tradeName: '',
      phone: '',
      email: '',
      billing: {
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
      },
      shipping: {
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
      },
      businessType: '',
      yearStarted: '',
      buildingType: '',
      webAddress: '',
      apContact: '',
      apEmail: '',
      apPhone: '',
      requestType: '',
      creditLimit: '',
      bankRef: {
        name: '',
        contactName: '',
        phone: '',
        streetAddress: '',
        city: '',
        province: '',
        postalCode: '',
        dateOpened: '',
      },
      references: [],
    },
  })

  handleNext = e => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }))
    e.preventDefault()
  }

  handleBack = e => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }))
    e.preventDefault()
  }

  handleReset = e => {
    this.setState(() => this.getInitState())
    e.preventDefault()
  }

  addReference = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        references: prevState.formData.references.concat({
          name: '',
          contactName: '',
          phone: '',
          streetAddress: '',
          city: '',
          province: '',
          postalCode: '',
          accountNo: '',
        }),
      },
    }))
  }

  removeReference = i => {
    this.setState(prevState => {
      const { references } = prevState.formData
      references.splice(i, 1)
      return { formData: { ...prevState.formData, references } }
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [parent]: { ...prevState.formData[parent], [child]: value },
        },
      }))
    } else {
      this.setState(prevState => ({
        formData: { ...prevState.formData, [name]: value },
      }))
    }
  }

  handleChangeInRef = (e, i) => {
    const { name, value } = e.target
    this.setState(prevState => {
      const { references } = prevState.formData
      references[i][name] = value
      return {
        formData: { ...prevState.formData, references },
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ status: 'submitting' })

    const { formData: data } = this.state
    const formData = encodeToFormData({
      'form-name': e.target.getAttribute('name'),
      ...data,
    })

    // append pdf: generated by the react-pdf
    data.signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    const now = getFormattedTime()
    const blob = await pdf(<CreditApplicationPdf data={data} />).toBlob()
    formData.append('file', blob, `credit-application-form_${now}.pdf`)

    fetch('/', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        let status = null
        if (res.ok) status = 'success'
        else status = 'failed'
        this.setState({ activeStep: this.steps.length - 1, status })
        //console.info(res)
      })
      .catch(err => {
        this.setState({ activeStep: this.steps.length - 1, status: 'failed' })
        //console.error(err)
      })
  }

  render() {
    const { activeStep, status, formData: values } = this.state
    return (
      <Layout>
        <SEO title="Credit Application Form | RoadLinx Shipping Company" />
        <section className="credit-application-form">
          <Container fluid className="credit-application-form__header">
            <h1 className="credit-application-form__title">
              Credit Application Form
            </h1>
          </Container>
          <Container
            className="credit-application-form__container"
            fluid={false}
          >
            <form
              name="Credit Application Form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="legalName" />
              <input type="text" name="tradeName" />
              <input type="text" name="phone" />
              <input type="text" name="email" />
              <input type="text" name="billing.streetAddress" />
              <input type="text" name="billing.city" />
              <input type="text" name="billing.province" />
              <input type="text" name="billing.postalCode" />
              <input type="text" name="shipping.streetAddress" />
              <input type="text" name="shipping.city" />
              <input type="text" name="shipping.province" />
              <input type="text" name="shipping.postalCode" />
              <input type="text" name="businessType" />
              <input type="text" name="yearStarted" />
              <input type="text" name="buildingType" />
              <input type="text" name="webAddress" />
              <input type="text" name="apContact" />
              <input type="text" name="apEmail" />
              <input type="text" name="apPhone" />
              <input type="text" name="requestType" />
              <input type="text" name="creditLimit" />
              <input type="text" name="bankRef.name" />
              <input type="text" name="bankRef.contactName" />
              <input type="text" name="bankRef.phone" />
              <input type="text" name="bankRef.streetAddress" />
              <input type="text" name="bankRef.city" />
              <input type="text" name="bankRef.province" />
              <input type="text" name="bankRef.postalCode" />
              <input type="text" name="bankRef.dateOpened" />
              <input type="text" name="references[]" />
              <input type="file" name="file" accept=".pdf" />
            </form>
            <Stepper activeStep={activeStep} alternativeLabel>
              {this.steps.map(label => (
                <Step className="stepper-Icons" key={label}>
                  <StepLabel className="credit-application-form__heading">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {(status === 'init' || status === 'submitting') && (
              <form
                name="Credit Application Form"
                method="POST"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="Credit Application Form"
                />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleChange}
                />
                <h3 className="mb-2">{this.steps[activeStep]}</h3>
                {activeStep === 0 && (
                  <>
                    <Row>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Legal Business Name"
                          name="legalName"
                          value={values.legalName}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Trade Name-DBA"
                          name="tradeName"
                          value={values.tradeName}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Phone"
                          name="phone"
                          value={values.phone}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Email"
                          name="email"
                          value={values.email}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Billing Address"
                          name="billing.streetAddress"
                          value={values.billing.streetAddress}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="City"
                          name="billing.city"
                          value={values.billing.city}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Province"
                          name="billing.province"
                          value={values.billing.province}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Postal Code"
                          name="billing.postalCode"
                          value={values.billing.postalCode}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Shipping Address"
                          name="shipping.streetAddress"
                          value={values.shipping.streetAddress}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="City"
                          name="shipping.city"
                          value={values.shipping.city}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Province"
                          name="shipping.province"
                          value={values.shipping.province}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Postal Code"
                          name="shipping.postalCode"
                          value={values.shipping.postalCode}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 mt-5">
                      <FormLabel component="label">Business Is a:</FormLabel>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="businessType"
                        value={values.businessType}
                        onChange={this.handleChange}
                        style={{ marginLeft: '10px', marginTop: '-10px' }}
                      >
                        <FormControlLabel
                          className="MuiFormLabel-root"
                          value="corporation"
                          control={<Radio />}
                          label="Corporation"
                        />
                        <FormControlLabel
                          className="MuiFormLabel-root"
                          value="llc"
                          control={<Radio />}
                          label="LLC"
                        />
                        <FormControlLabel
                          className="MuiFormLabel-root"
                          value="partnership"
                          control={<Radio />}
                          label="Partnership"
                        />
                        <FormControlLabel
                          className="MuiFormLabel-root"
                          value="proprietorship"
                          control={<Radio />}
                          label="Proprietorship"
                        />
                      </RadioGroup>
                    </Row>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <Row>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Year Started"
                          name="yearStarted"
                          value={values.yearStarted}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={5} className="mt-4" style={{ display: 'flex' }}>
                        <FormLabel component="label">Building:</FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="buildingType"
                          value={values.buildingType}
                          onChange={this.handleChange}
                          style={{ marginLeft: '10px', marginTop: '-12px' }}
                        >
                          <FormControlLabel
                            className="MuiFormLabel-root"
                            value="owned"
                            control={<Radio />}
                            label="Owned"
                          />
                          <FormControlLabel
                            className="MuiFormLabel-root"
                            value="leased"
                            control={<Radio />}
                            label="Leased"
                          />
                        </RadioGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <TextField
                          fullWidth
                          placeholder="Web Site Address"
                          name="webAddress"
                          value={values.webAddress}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="A/P Contact"
                          name="apContact"
                          value={values.apContact}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="A/P Email"
                          name="apEmail"
                          value={values.apEmail}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="A/P Phone"
                          name="apPhone"
                          value={values.apPhone}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col md={8} style={{ display: 'flex' }}>
                        <FormLabel component="label">
                          Terms Requested:
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="terms requested"
                          name="requestType"
                          value={values.requestType}
                          onChange={this.handleChange}
                          style={{ marginLeft: '10px', marginTop: '-10px' }}
                        >
                          <FormControlLabel
                            className="MuiFormLabel-root"
                            value="cod"
                            control={<Radio />}
                            label="COD"
                          />
                          <FormControlLabel
                            className="MuiFormLabel-root"
                            value="credit-card"
                            control={<Radio />}
                            label="Credit Card"
                          />
                          <FormControlLabel
                            className="MuiFormLabel-root"
                            value="net-terms"
                            control={<Radio />}
                            label="Net Terms"
                          />
                        </RadioGroup>
                      </Col>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="Credit Limit Requested"
                          name="creditLimit"
                          type="number"
                          value={values.creditLimit}
                          onChange={this.handleChange}
                          size="medium"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <Row>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="Name"
                          name="bankRef.name"
                          value={values.bankRef.name}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="Contact Name"
                          name="bankRef.contactName"
                          value={values.bankRef.contactName}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          fullWidth
                          placeholder="Phone"
                          name="bankRef.phone"
                          value={values.bankRef.phone}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={12}>
                        <TextField
                          fullWidth
                          placeholder="Street Address"
                          name="bankRef.streetAddress"
                          value={values.bankRef.streetAddress}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="City"
                          name="bankRef.city"
                          value={values.bankRef.city}
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Province"
                          name="bankRef.province"
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Postal Code"
                          name="bankRef.postalCode"
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          fullWidth
                          placeholder="Date Opened"
                          type="date"
                          name="bankRef.dateOpened"
                          onChange={this.handleChange}
                          size="medium"
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 mt-5">
                      <span className="h3 mr-3">References</span>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={this.addReference}
                      >
                        Add New Reference
                      </Button>
                    </Row>

                    {values.references.map((ref, i) => (
                      <div key={i}>
                        <div className="mt-5">
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            className="p-1 mr-2"
                            onClick={() => this.removeReference(i)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <span className="h5">Reference No. {i + 1}</span>
                        </div>

                        <Row>
                          <Col md={4}>
                            <TextField
                              fullWidth
                              placeholder="Name"
                              name="name"
                              value={ref.name}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                          <Col md={4}>
                            <TextField
                              fullWidth
                              placeholder="Contact Name"
                              name="contactName"
                              value={ref.contactName}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                          <Col md={4}>
                            <TextField
                              fullWidth
                              placeholder="Phone"
                              name="phone"
                              value={ref.phone}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={12}>
                            <TextField
                              fullWidth
                              placeholder="Street Address"
                              name="streetAddress"
                              value={ref.streetAddress}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={3}>
                            <TextField
                              fullWidth
                              placeholder="City"
                              name="city"
                              value={ref.city}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                          <Col md={3}>
                            <TextField
                              fullWidth
                              placeholder="Province"
                              name="province"
                              value={ref.province}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                          <Col md={3}>
                            <TextField
                              fullWidth
                              placeholder="Postal Code"
                              name="postalCode"
                              value={ref.postalCode}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                          <Col md={3}>
                            <TextField
                              fullWidth
                              placeholder="Account Number"
                              name="accountNo"
                              value={ref.accountNo}
                              onChange={e => this.handleChangeInRef(e, i)}
                              size="medium"
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Row className="mt-5 align-items-center">
                      <Col lg={1}>
                        <div className="h5">Signature </div>
                        <div className="mt-5">
                          <Button
                            variant="text"
                            color="secondary"
                            size="small"
                            onClick={() => this.sigPad.clear()}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                      <Col>
                        <SignatureCanvas
                          ref={ref => {
                            this.sigPad = ref
                          }}
                          dotSize={1}
                          throttle={2}
                          canvasProps={{
                            width: 240,
                            height: 180,
                            className: 'sigCanvas',
                            style: { border: '2px solid black' },
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="m-0 mt-5">
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    className="mr-5"
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="mr-2"
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                  >
                    Back
                  </Button>
                  {activeStep === 2 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={status === 'submitting'}
                    >
                      {status === 'submitting' ? 'Submitting' : 'Submit'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  )}
                </Row>
              </form>
            )}
            {status === 'success' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-success">
                  <CheckCircleIcon style={{ fontSize: 24 }} />
                </div>
                <h3 className="mb-4">
                  Your form has been submitted successfully.
                </h3>
              </div>
            )}
            {status === 'failed' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-danger">
                  <ErrorIcon style={{ fontSize: 24 }} />
                </div>
                <h3 className="mb-4 h5">Oops! something went wrong...</h3>
                <h3>
                  Please try reloading the page and submit the form again.
                </h3>
              </div>
            )}
          </Container>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default CreditAppForm
