import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
} from '@react-pdf/renderer'

import logo from '../../../images/logo-colored.jpg'
import OpenSansRegular from '../../../../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../../../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'Open Sans',
  fonts: [{ src: OpenSansRegular }, { src: OpenSansBold, fontWeight: 700 }],
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Open Sans',
    fontSize: 10,
    padding: 30,
  },
  logo: {
    width: 155.6,
    height: 120.8,
  },
  heading1: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 12,
  },
  heading2: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 4,
  },
  textBold: {
    fontWeight: 700,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { padding: 4 },
  sig: {
    width: 48,
    height: 36,
  },
})

const TERMS = [
  'The Creditor shall provide an invoice (the “Invoice”) to the Applicant and the Applicant shall pay any amount outstanding on the Invoice within fifteen (15) days of the date of the Invoice (the “Due Date”).',
  'The Applicant understands and acknowledges that no monthly statements will be issued by the Creditor, and that late payment shall be subject to any applicable service charges and interest at the rate of twenty-four percent (24%) per annum, calculated monthly, on any amounts outstanding. The Applicant further agrees that if an Invoice is paid with a cheque which is returned for non-sufficient funds, a fee of fifty dollars ($50.00) will be charged as an administrative fee to the Applicant and any subsequent payments towards said Invoice must be made by certified funds.',
  'The Applicant agrees to be bound by any terms or conditions set out in the Invoice. In the event of any conflict between the terms and conditions of this Application and the Invoice, this Application shall govern.',
  'The Applicant acknowledges that all freight charges must be paid before any claims can be processed, and that claims cannot be deducted from any freight charges.',
  'The Creditor offers limited insurance on all shipments, calculated based on the weight of the shipment OR the replacement value of the shipment, including freight charges and any other charges paid. The Creditor’s insurance on shipments valued by weight is two dollars per pound ($2.00/lb). If the shipment is of a higher value than the Creditor’s insurance, the Applicant must obtain additional insurance coverage for such shipment.',
  'The Applicant consents to and authorizes the Creditor to obtain personal and financial information regarding the Applicant from credit reporting agencies, financial institutions and other sources as the Creditor deems appropriate, in its sole and unfettered discretion.',
  'The Applicant warrants that the information provided to the Creditor in this Application is accurate, true and correct. The Applicant acknowledges the Creditor is relying on said information for the purposes of evaluating the Application and that any inaccuracies in the information provided may delay the Creditor’s evaluation of the Application or result in the Application being rejected.',
  'The Applicant acknowledges that neither the Creditor nor any of its agents or representatives has made any oral or written representations or warranties concerning the services or any of the terms contemplated herein, of any nature whatsoever and that the Applicant will be relying on its own independent investigation thereof. The Applicant further acknowledges that, in submitting this Application, it has not relied on any representations or warranties about the subject matter of this Application except as expressly set forth herein.',
  'The Applicant hereby irrevocably and unconditionally releases, discharges and agrees to indemnify and hold harmless the Creditor, its parents, subsidiaries, registered trade names, and affiliates, and the irrespective officers, directors, employees, agents, assignees, designees and licensees, from and against all actions, claims, demands, causes of action, liabilities, damages, judgments, losses, costs, and expenses (including lawyers’ fees) of any kind whatsoever, whether known or unknown, arising at any time out of and/or relating to this Application, any breach or alleged breach of any of the terms of this Application or in relation to any services to be provided by the Creditor.',
  'In no event shall the Creditor be liable to the Applicant or to any third party for any loss of use, revenue, loss or diminution in value, or for any consequential, incidental, indirect, exemplary, special or punitive damages whether arising out of breach of contract, tort (including negligence) or otherwise, regardless of whether such damage was foreseeable and whether or not the Creditor has been advised of the possibility of such damages.',
  'Notwithstanding the generality of the foregoing, in no event shall the Creditor’s aggregate liability arising out of the Application or related to the services to be performed by the Creditor, whether arising out of or related to breach of contract, tort (including negligence) or otherwise, exceed the aggregate amounts paid or payable to the Creditor pursuant to any Invoices.',
  'The Applicant hereby consents to and acknowledges that it has been advised and afforded the opportunity to obtain independent legal advice with respect to the terms of this Application prior to signing this Application. The Applicant hereby acknowledges that it has entered into this Application voluntarily and has obtained independent legal advice with respect to the terms of this Application or has declined to seek such independent legal advice despite having been given the opportunity to do so.',
  'For the purposes of this Application, “Force Majeure” means events that are caused by or result from acts beyond the parties’ reasonable control, which events may include but are not limited to natural disasters, embargoes, explosions, riots, wars or acts of invasion or terrorism, requirements of law, national or regional emergency, pandemics, including the 2019 novel coronavirus disease (COVID-19) pandemic, epidemics, strikes, labour stoppages or slowdowns, or shortage of adequate power or transportation. Neither party will be responsible or liable in any way for failure or delay in performing its obligations under this Application during any period in which such performance is prevented or hindered by an event of Force Majeure. During such period each party’s obligations, to the extent that they are affected by the event of Force Majeure, will be suspended and commensurately extended until such time as performance is no longer prevented or hindered. Notwithstanding the foregoing, the obligations of the Applicant including, but not limited to, all payments owed to the Creditor hereunder, shall remain notwithstanding any Force Majeure.',
  'This Application may be executed either in original or electronic form and the parties adopt any signatures received by fax or by e-mail as original signatures of the Applicant’s authorized representatives.',
  'This Agreement is governed by the laws of the Province of Ontario and the laws of Canada applicable therein. The Parties submit to the exclusive jurisdiction of the courts of the Province of Ontario in the City of Toronto for all disputes arising from or relating to this Application or the services to be provided by the Creditor.',
]

const CreditAppPdf = ({ data }) => (
  <Document style={{ width: '100%' }}>
    <Page size="LETTER" style={styles.page}>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Image style={styles.logo} source={logo} />
        </View>
        <View style={{ marginLeft: 224, marginTop: 16, lineHeight: 2 }}>
          <Text style={{ fontWeight: 700 }}>Roadlinx Inc.</Text>
          <Text>501 Rowntree Dairy Road, Unit 4</Text>
          <Text>Woodbridge, ON L4L 8H1</Text>
          <Text>905-760-1141</Text>
          <Link src="mailto:accounting@roadlinx.com">
            accounting@roadlinx.com
          </Link>
        </View>
      </View>

      {/* Parcel Information */}
      <View style={{ marginTop: 16 }}>
        <View style={[styles.table, { borderWidth: 2 }]}>
          {/* General */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Legal Business Name</Text>
                <Text>{data.legalName}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Trade Name-DBA</Text>
                <Text>{data.tradeName}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Phone</Text>
                <Text>{data.phone}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Email</Text>
                <Text>{data.email}</Text>
              </View>
            </View>
          </View>

          {/* Billing */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Billing Address</Text>
                <Text>{data.billing.streetAddress}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>City</Text>
                <Text>{data.billing.city}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Province</Text>
                <Text>{data.billing.province}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Postal Code</Text>
                <Text>{data.billing.postalCode}</Text>
              </View>
            </View>
          </View>

          {/* Shipping */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Shipping Address</Text>
                <Text>{data.shipping.streetAddress}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>City</Text>
                <Text>{data.shipping.city}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Province</Text>
                <Text>{data.shipping.province}</Text>
              </View>
            </View>
            <View style={styles.tableCol}>
              <View style={styles.tableCell}>
                <Text>Postal Code</Text>
                <Text>{data.shipping.postalCode}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text>Business is a: {data.businessType}</Text>
      </View>

      {/* Bank Transfer */}
      <View style={{ marginTop: 10, flexDirection: 'row' }}>
        <Text style={{ marginRight: 36 }}>
          Year Started: {data.yearStarted}
        </Text>
        <Text>Building: {data.buildingType}</Text>
      </View>

      <View style={{ marginTop: 10, flexDirection: 'row' }}>
        <Text style={{ marginRight: 4 }}>Web Site Address:</Text>
        <Link src={data.webAddress}>{data.webAddress}</Link>
      </View>

      <View style={{ marginTop: 10, flexDirection: 'row' }}>
        <Text style={{ marginRight: 36 }}>A/P Contact: {data.apContact}</Text>
        <Text style={{ marginRight: 36 }}>A/P Email: {data.apEmail}</Text>
        <Text>A/P Phone: {data.apEmail}</Text>
      </View>

      <View style={{ marginTop: 10, flexDirection: 'row' }}>
        <Text style={{ marginRight: 36 }}>
          Terms Requested: {data.requestType}
        </Text>
        <Text>Credit Limit Requested: ${data.creditLimit}</Text>
      </View>

      {/* Bank References */}
      <View style={{ marginTop: 10 }}>
        <Text style={styles.heading2}>Bank References</Text>
        <View style={[styles.table, { borderWidth: 2 }]}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>Name</Text>
                <Text>{data.bankRef.name}</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>Contact Name</Text>
                <Text>{data.bankRef.contactName}</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>Phone</Text>
                <Text>{data.bankRef.phone}</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>Street Address</Text>
                <Text>{data.bankRef.streetAddress}</Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>City, Province, Postal Code</Text>
                <Text>
                  {data.bankRef.city}, {data.bankRef.province},{' '}
                  {data.bankRef.postalCode}
                </Text>
              </View>
            </View>
            <View style={[styles.tableCol, { width: '33.33%' }]}>
              <View style={styles.tableCell}>
                <Text>Date of Account Opening</Text>
                <Text>{data.bankRef.dateOpened}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/* References */}
      <View style={{ marginTop: 10 }}>
        <Text style={styles.heading2}>References</Text>
        <View style={[styles.table, { borderWidth: 2 }]}>
          {data.references.map((ref, i) => (
            <View key={i}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>{i + 1}. Name</Text>
                    <Text>{ref.name}</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>Contact Name</Text>
                    <Text>{ref.contactName}</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>Phone</Text>
                    <Text>{ref.contactName}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>Street Address</Text>
                    <Text>{ref.streetAddress}</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>City, Province, Postal Code</Text>
                    <Text>
                      {ref.city}, {ref.province}, {ref.postalCode}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '33.33%' }]}>
                  <View style={styles.tableCell}>
                    <Text>Account Number</Text>
                    <Text>{ref.accountNo}</Text>
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>

    <Page size="LETTER" style={styles.page}>
      <View>
        <Text style={styles.heading1}>
          ROADLINX CREDIT APPLICATION – TERMS AND CONDITIONS:
        </Text>
        <Text style={{ textAlign: 'justify' }}>
          The business as set out in the first page of this Credit Application
          (the <Text style={styles.textBold}>“Applicant”</Text>) hereby submits
          this credit application (the{' '}
          <Text style={styles.textBold}>“Applicant”</Text>) to RoadLINX Inc.
          (the <Text style={styles.textBold}>“Creditor”</Text>) and agrees to be
          bound by the following terms and conditions in the event that the
          Applicant’s Credit Application is approved by the Creditor:
        </Text>
        {TERMS.map((term, i) => (
          <View
            style={{ flexDirection: 'row', marginTop: 12, paddingRight: 16 }}
            key={i}
          >
            <Text style={{ marginRight: 6 }}>{i + 1}.</Text>
            <Text style={{ textAlign: 'justify' }}>{term}</Text>
          </View>
        ))}
      </View>

      <Text style={{ marginTop: 16 }}>
        <Text style={styles.textBold}>IN WITNESS WHEREOF</Text> the undersigned,
        being the Applicant, has accepted and agrees to be bound by the
        foregoing terms and conditions.
      </Text>

      <View style={{ flexDirection: 'row', marginTop: 32 }}>
        <View style={{ flexDirection: 'column', marginRight: 36 }}>
          <Image style={styles.sig} source={data.signature} />
          <Text>Signature</Text>
        </View>
        <View style={{ flexDirection: 'column', marginRight: 24 }}>
          <Text>__________________________</Text>
          <Text>Date</Text>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <Text>______________________________</Text>
          <Text>Title</Text>
        </View>
      </View>
    </Page>
  </Document>
)

export default CreditAppPdf
